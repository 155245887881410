import React from 'react';

export default class LandingHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      dataisLoaded: false,
    };
  }

  //Used to execute the code
  componentDidMount() {
    fetch("https://jsonplaceholder.typicode.com/users")
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          items: json,
          dataisLoaded: true,
        });
      });
  }

  render() {
    const { dataisLoaded, items } = this.state;

    if (!dataisLoaded) return <div>Loading...</div>;

    return (
      <>
        <div>
          <h1> Fetch data from an api in react </h1>{" "}
          {items.map((item) => (
            <ol key={item.id}>
              User_Name: {item.username}, Full_Name: {item.name}, User_Email:{" "}
              {item.email}
            </ol>
          ))}
        </div>
      </>
    );
  }
}