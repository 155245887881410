import React, { Component } from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import LandingHome from "./modules/landing/home-landing";

class App extends Component {
  render() {
    return (
      <div>
          <Routes>
            <Route path="/" element={<LandingHome></LandingHome>}></Route>
          </Routes>
        </div>
    );
  }
}

export default App;
